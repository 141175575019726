import React from 'react'

const Rejection = () => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='201'
        height='201'
        viewBox='0 0 201 201'
        fill='none'
      >
        <path
          d='M100.182 200.182C155.411 200.182 200.182 155.41 200.182 100.182C200.182 44.9532 155.411 0.181641 100.182 0.181641C44.9537 0.181641 0.182129 44.9532 0.182129 100.182C0.182129 155.41 44.9537 200.182 100.182 200.182Z'
          fill='#E12127'
        />
        <path
          d='M100.182 180.841C144.729 180.841 180.841 144.729 180.841 100.182C180.841 55.6355 144.729 19.5234 100.182 19.5234C55.6355 19.5234 19.5234 55.6355 19.5234 100.182C19.5234 144.729 55.6355 180.841 100.182 180.841Z'
          fill='#EE3A3A'
        />
        <path
          d='M138.385 120.859L117.706 100.181L138.384 79.5031C143.223 74.6638 143.223 66.818 138.384 61.9789C133.544 57.1398 125.698 57.1398 120.86 61.9789L100.182 82.6573L79.5036 61.9797C74.6642 57.1407 66.8187 57.1406 61.9793 61.98C57.1403 66.819 57.1403 74.6651 61.9797 79.5042L82.658 100.182L61.9805 120.86C57.1414 125.7 57.1414 133.545 61.9807 138.384C66.8201 143.223 74.6659 143.223 79.505 138.384L100.183 117.706L120.861 138.383C125.7 143.222 133.546 143.222 138.385 138.383C143.224 133.544 143.224 125.698 138.385 120.859Z'
          fill='white'
        />
      </svg>
    </>
  )
}

export default Rejection
