import React from 'react'
import { Card } from 'reactstrap'
import Info from '../../assets/images/Info'

const Responded = () => {
  return (
    <div className='min-vh-100 d-flex justify-content-center align-items-center'>
      <Card className='w-100 m-3' style={{ maxWidth: '1300px' }}>
        <main className='text-center p-4'>
          <Info />
          <section>
            <h1 className='h4 mb-3 mt-4'>Outdated Request</h1>
            <p className='lead'>
              This request has already been approved or rejected.
            </p>
            <p className='lead'>Thank you!</p>
          </section>
        </main>
      </Card>
    </div>
  )
}

export default Responded
