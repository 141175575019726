import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Toolbar } from 'primereact/toolbar'
import MainContentLayout from '../Layout/MainContentLayout'
import server from '../../server'
import ModifyAds from './ModifyAds'
import { formatDate } from '../shared/utils'

import { InputFieldTemplate } from '../shared/FilterTemplates'

const AdsControlPanel = () => {
  const [clearBtnDisabled, setClearBtnDisabled] = useState(true)
  const [lazyState, setLazyState] = useState({ rows: 10, page: 0, first: 0 })
  const [tableLoading, setTableLoading] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [ads, setAds] = useState([])

  const TitleFilterElement = (options) => (
    <InputFieldTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e, options.index)}
      placeholder='Title'
    />
  )

  const dt = useRef()

  const AddEditRef = useRef()
  const DeleteRef = useRef()
  const refs = { AddEditRef, DeleteRef }

  const getData = () => {
    const title = lazyState?.filters?.title?.value || '' // Filter for title

    const requestParams =
      `?page=${lazyState?.page ? lazyState.page + 1 : 1}` +
      `&limit=${lazyState?.rows}` +
      `${title ? '&title=' + title : ''}`
    setTableLoading(true)

    server
      .get(`/ads/${requestParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        const adsData = response?.data?.ads || []
        setAds(adsData)
        setTotalRecords(response.data.totalRecords || 0)
        setTableLoading(false)
        // setError(null)
      })
      .catch((error) => {
        setTableLoading(false)
        // setError(error.response.data.message)
      })
  }
  // Enable and disable clear button according to filtring and sorting
  const checkClearBtn = () => {
    if (lazyState?.sortField || lazyState?.sortOrder) {
      setClearBtnDisabled(false)
      return
    }
    for (const key in lazyState?.filters) {
      if (lazyState?.filters[key].value) {
        setClearBtnDisabled(false)
        return
      }
    }
    setClearBtnDisabled(true)
  }
  useEffect(() => initLazyState(), [])
  useEffect(() => {
    checkClearBtn()
    return getData()
  }, [lazyState])

  const initLazyState = () => {
    setLazyState((prevState) => ({
      ...prevState,
      sortField: '',
      sortOrder: '',
      filters: {
        title: { value: '' },
        expiryDate: { value: '' },
        status: { value: '' },
      },
    }))
  }
  const expiryDateBodyTemplate = (rowData) => {
    return formatDate(rowData.expiryDate)
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className='d-flex justify-content-center gap-2'>
          <Button
            icon='pi pi-pencil'
            rounded
            outlined
            className='mr-2'
            onClick={() => AddEditRef.current.editAdvertisement(rowData)}
          />

          <Button
            icon='pi pi-trash'
            rounded
            outlined
            severity='danger'
            onClick={() => DeleteRef.current.confirmDeleteAds(rowData)}
          />
        </div>
      </React.Fragment>
    )
  }
  const statusBodyTemplate = (rowData) => {
    return rowData.active ? 'Active' : 'Inactive'
  }
  const topRightToolbarTemplate = () => {
    return (
      <div className='d-flex'>
        <Button
          label='New advertisement'
          icon='pi pi-plus'
          severity='success'
          onClick={() => AddEditRef.current.openNew()}
        />
      </div>
    )
  }
  // Toolbar body templates
  const topLeftToolbarTemplate = () => {
    return (
      <div className='d-flex'>
        <Button
          type='button'
          icon='pi pi-filter-slash'
          label='Clear'
          outlined
          onClick={initLazyState}
          disabled={clearBtnDisabled}
        />
      </div>
    )
  }
  // Handles table page change
  const onPage = (event) => {
    setLazyState(event)
  }

  // Handles table sorting
  const onSort = (event) => {
    setLazyState(event)
  }

  // Handles table filtring
  const onFilter = (event) => {
    setLazyState(event)
  }

  return (
    <>
      <MainContentLayout title='Advertisements'>
        <Toolbar
          className='mb-2'
          left={topLeftToolbarTemplate}
          right={topRightToolbarTemplate}
        />
        <DataTable
          ref={dt}
          lazy
          paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          paginator
          value={ads}
          tableStyle={{ minWidth: '50rem' }}
          loading={tableLoading}
          filters={lazyState?.filters}
          sortField={lazyState?.sortField}
          sortOrder={lazyState?.sortOrder}
          rows={lazyState?.rows}
          first={lazyState?.first}
          onPage={onPage}
          onFilter={onFilter}
          removableSort
          emptyMessage='No ads found in the selected data range'
        >
          <Column
            field='title'
            header='Title'
            filter
            filterElement={TitleFilterElement}
            showFilterOperator={false}
            showAddButton={false}
            showFilterMenuOptions={false}
          />
          <Column
            field='expiryDate'
            header='Expiry Date'
            body={expiryDateBodyTemplate}
            showFilterOperator={false}
            showAddButton={false}
            showFilterMenuOptions={false}
          />

          <Column field='status' header='Status' body={statusBodyTemplate} />
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: '12rem' }}
          ></Column>
        </DataTable>
        <ModifyAds ref={refs} getData={getData} />
      </MainContentLayout>
    </>
  )
}

export default AdsControlPanel
