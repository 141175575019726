import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { AuthenticationContextProvider } from './Auth/authentication.context'
import * as Sentry from '@sentry/react'

const IP = 'api.originssystems.com'

Sentry.init({
  dsn: 'https://e24b9d56c70cb8878783cb4bfb9bb630@o4504542497275904.ingest.us.sentry.io/4508086744776704',

  tracesSampleRate: 1.0,
  tracePropagationTargets: [`/^https:${IP}/`],
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <AuthenticationContextProvider>
      <App />
    </AuthenticationContextProvider>
  </Sentry.ErrorBoundary>
)
