import React, { useContext, useRef, useEffect, useState } from 'react'
import { AuthenticationContext } from '../../Auth/authentication.context'

import { Label, Form, FormFeedback, Input,InputGroup, InputGroupText } from 'reactstrap'

import { Link, Navigate } from 'react-router-dom'
import { SelectButton } from 'primereact/selectbutton'

// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Toast } from 'primereact/toast'

import AuthFormsLayout from '../Layout/AuthFormsLayout'
import { Card } from 'reactstrap'

import { ToastPopUp } from '../shared/utils'

import AuthLayout from '../Layout/AuthLayout'

const LoginManufacturer = (props) => {
  const [showPassword, setShowPassword] = useState(false)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const { onLogin, user, isAdmin, error, toastStatus } = useContext(
    AuthenticationContext
  )

  const toast = useRef(null)

  useEffect(() => {
    if (toastStatus.toastStatus !== undefined) {
      ToastPopUp(
        toast,
        toastStatus?.toastStatus,
        toastStatus?.summary,
        toastStatus?.msg
      )
    }
  }, [toastStatus])
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed

    initialValues: {
      email: '',
      password: '',
      role: 'manufacturer',
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please Enter Valid Email')
        .required('Please Enter Your User Name'),
      password: Yup.string().min(8).required('Please Enter Your Password'),
    }),
    onSubmit: (values) => {
      onLogin(values.email, values.password, 'manufacturer')
    },
  })

  document.title = 'Login'

  const options = ['Pharmacy', 'Manufacturer']
  const [value, setValue] = useState(options[0])

  return (
    <>
      <AuthLayout>
        <div className='card flex'>
          <Toast ref={toast} />
        </div>
        {user ? (
          <Navigate to='/' />
        ) : (
          <>
            {/* <AuthFormsLayout
            title='Welcome Back !'
            subtitle='Sign in to continue to Origins Direct'
          > */}
            <Card className='p-4'>
              <h2>Let's log you in Origins Direct</h2>
              <h3 className='text-center'>Login as a Manufacturer</h3>
              <Form
                className='mt-4'
                onSubmit={(e) => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
                action='#'
                noValidate
              >
                {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
                {/* <div className='d-flex justify-content-center align-items-center'>
                  <SelectButton
                    value={value}
                    onChange={(e) => setValue(e.value)}
                    options={options}
                  />
                </div> */}
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='email'>
                    Email
                  </Label>
                  <Input
                    name='email'
                    className='form-control'
                    placeholder='Enter Email'
                    type='email'
                    id='email'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ''}
                    invalid={
                      validation.touched.email && validation.errors.email
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type='invalid'>
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className='mb-3'>
                  <InputGroup>
                    <Input
                      name='password'
                      value={validation.values.password || ''}
                      type={showPassword ? 'text' : 'password'}
                      className='form-control'
                      placeholder='Enter Password'
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.password &&
                        !!validation.errors.password
                      }
                    />
                    <InputGroupText
                      onClick={togglePasswordVisibility}
                      style={{ cursor: 'pointer' }}
                    >
                      <i
                        className={`mdi ${
                          showPassword
                            ? 'mdi-eye-off-outline'
                            : 'mdi-eye-outline'
                        }`}
                      ></i>
                    </InputGroupText>
                  </InputGroup>
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type='invalid'>
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className='mb-3 row'>
                  <div className='col-sm-6'>
                    <div className='form-check'>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        id='customControlInline'
                      />
                      <label
                        className='form-check-label'
                        htmlFor='customControlInline'
                      >
                        Remember me
                      </label>
                    </div>
                  </div>
                  <div className='col-sm-6 text-end'>
                    <button
                      className='btn btn-primary w-md waves-effect waves-light'
                      type='submit'
                    >
                      Log In
                    </button>
                  </div>
                </div>

                <div className='mt-2 mb-0 row'>
                  <div className='col-12 mt-4'>
                    <Link to='/forget-password'>
                      <i className='mdi mdi-lock'></i> Forget your password?
                    </Link>
                  </div>
                </div>
              </Form>
              {/* </AuthFormsLayout> */}

              <div className='mt-4  d-flex gap-2 justify-content-center'>
                <Link to='/login' className='fw-medium text-primary'>
                  Login as a Pharmacy ?
                </Link>

                <Link to='/admin-login' className='fw-medium text-primary'>
                  Origins Direct Team Login?
                </Link>
              </div>
            </Card>
          </>
        )}
      </AuthLayout>
    </>
  )
}

export default LoginManufacturer
