import React from 'react'
import { formatCurrency, formatPercentage } from '../../shared/utils'
const DashboardCards = ({ title, icon, value }) => {
  const formatDashboardValue = () => {
    if (title === "# of Orders") 
      return value 
    else {
      if( title === "Average Pa %")
        return formatPercentage(value)
      else
        return formatCurrency(value)
    }
  }
  return (
    <div className='mb-4'>
      <div className='float-start mini-stat-img me-4'>
        <img src={icon} alt='' />
      </div>
      <h5 className='font-size-13 text-uppercase mt-0 text-white-50'>
        {title}
      </h5>
      <h4 className='fw-medium font-size-16'>{formatDashboardValue()}</h4>
    </div>
  )
}

export default DashboardCards
