import React, { useContext } from 'react'
import ManufacturerDashboard from './ManufacturerDashboard'
import PharmacyDashboard from './PharmacyDashboard'
import AdminDashboard from './AdminDashboard'
import { AuthenticationContext } from '../../Auth/authentication.context'

const Index = () => {
  const { user } = useContext(AuthenticationContext)
  // Check if user exists and has a role
  if (user?.role === 'admin') {
    return <AdminDashboard />
  } else if (user?.role === 'manufacturer') {
    return <ManufacturerDashboard />
  } else if (user?.role === 'pharmacy') {
    return <PharmacyDashboard />
  } else {
    // Handle case when user doesn't have a recognized role
    return <div>User has no role or unrecognized role.</div>
  }
}

export default Index
