import React, { useContext } from 'react'

import { Navigate, Outlet } from 'react-router-dom'
import { AuthenticationContext } from '../Auth/authentication.context'

const AdminPrivate = () => {
  const { user } = useContext(AuthenticationContext)

  return <>{user.role === "admin" ? <Outlet /> : <Navigate to='/403' />}</>
}

export default AdminPrivate
