import React, { useEffect, useRef, useState } from 'react'

const PlacesAutocomplete = ({
  validation,
  setSelectedAddress,
  placeholder,
}) => {
  const [selectedPlace, setSelectedPlace] = useState(
    validation ? validation.values.address : ''
  )
  const inputRef = useRef(null)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACE_API}&libraries=places`
    script.async = true
    script.onload = () => {
      initAutocomplete()
    }

    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [])

  const initAutocomplete = () => {
    const input = inputRef.current

    if (!input) return

    const autocomplete = new window.google.maps.places.Autocomplete(input, {
      fields: ['formatted_address', 'geometry', 'name'],
      componentRestrictions: { country: ['ca'] }, 
      strictBounds: false,
    })

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()

      if (!place.geometry || !place.geometry.location) {
        console.error('No details available for input:', place.name)
        return
      }

      setSelectedPlace(place?.formatted_address)
      setSelectedAddress(place?.formatted_address)

      // You can perform any other actions with the selected place data here
    })
  }

  return (
    <div>
      <input
        id='pac-input'
        ref={inputRef}
        type='text'
        placeholder={`${placeholder ? placeholder : 'Enter a location'}`}
        className={`form-control ${
          validation?.touched.address && validation?.errors.address
            ? 'is-invalid'
            : ''
        }`}
        // value={selectedPlace}
        onChange={validation?.handleChange}
        onBlur={validation?.handleBlur}
      />
      {validation?.touched.address && validation?.errors.address && (
        <div className='invalid-feedback'>{validation?.errors.address}</div>
      )}
    </div>
  )
}
export default PlacesAutocomplete
