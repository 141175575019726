import React from 'react'
import { Card } from 'reactstrap'
import Rejection from '../../assets/images/rejection'
import { useSearchParams } from 'react-router-dom'

const ManufacturerRejection = () => {
  const [searchParams] = useSearchParams()

  const pharmacyName = searchParams.get('pharmacyName')
  const manufacturer = searchParams.get('manufacturer')
  return (
    <div className='min-vh-100 d-flex justify-content-center align-items-center'>
      <Card className='w-100 m-3' style={{ maxWidth: '1300px' }}>
        <main className='text-center p-4'>
          <Rejection />
          <section>
            <h1 className='h4 mb-3 mt-4'>Application Rejected</h1>
            <p className='lead'>
              {pharmacyName} application to use OD with {manufacturer} has been
              rejected.
            </p>
          </section>
        </main>
      </Card>
    </div>
  )
}

export default ManufacturerRejection
