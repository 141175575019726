import React from 'react'

const Info = () => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='200'
        height='200'
        viewBox='0 0 200 200'
        fill='none'
      >
        <g clip-path='url(#clip0_374_512)'>
          <path
            d='M99.9998 8.33301C150.625 8.33301 191.667 49.3748 191.667 99.9998C191.667 150.625 150.625 191.667 99.9998 191.667C49.3748 191.667 8.33301 150.625 8.33301 99.9998C8.33301 49.3748 49.3748 8.33301 99.9998 8.33301Z'
            fill='#D5D5D5'
          />
          <path
            d='M100 200C44.8582 200 0 155.142 0 100C0 44.8582 44.8582 0 100 0C155.142 0 200 44.8582 200 100C200 155.142 155.142 200 100 200ZM100 16.6668C54.05 16.6668 16.6668 54.05 16.6668 100C16.6668 145.95 54.05 183.333 100 183.333C145.95 183.333 183.333 145.95 183.333 100C183.333 54.05 145.95 16.6668 100 16.6668Z'
            fill='#404040'
          />
          <path
            d='M101.25 110.916C101.25 113.166 102.833 115.083 102.833 117.333C102.833 120.691 100.108 123.416 96.7501 123.416C96.6919 123.416 96.6333 123.416 96.5833 123.416C90.3333 123.416 87.3333 114.5 87.3333 109.666C87.3333 89.7495 114.583 87.8331 114.583 72.4995C114.583 65.3327 109.167 59.2495 97.9165 59.2495C90.4747 59.2163 83.3333 62.2245 78.1665 67.5827C76.8747 68.7159 75.2165 69.3409 73.4997 69.3327C69.8079 69.1995 66.8747 66.1909 66.8329 62.4995C66.8079 60.9913 67.3079 59.5163 68.2497 58.3327C76.3247 49.7077 87.6915 44.9245 99.4997 45.1659C119.25 45.1659 131.25 55.9991 131.25 70.1659C130.917 93.8327 101.25 96.3327 101.25 110.916ZM97.9169 154.666C92.4419 154.666 88.0001 150.225 88.0001 144.75C88.0919 139.308 92.4751 134.925 97.9169 134.833C103.4 134.966 107.792 139.433 107.834 144.916C107.658 150.291 103.292 154.583 97.9169 154.666Z'
            fill='#404040'
          />
        </g>
        <defs>
          <clipPath id='clip0_374_512'>
            <rect width='200' height='200' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}

export default Info
