import React, { useContext } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthenticationContext } from '../Auth/authentication.context';

const StatusAccessControl = () => {
  const { status } = useContext(AuthenticationContext)
  const location = useLocation();
  const statusAccessMap = {
    0: ['/pharmacy/profile'],                 
    1: ['/pharmacy/profile', '/pharmacy/manufacturers'],  
    2: ['/pharmacy/profile', '/pharmacy/manufacturers'],  
    3: ['/pharmacy/profile', '/pharmacy/manufacturers', '/pharmacy/add-orders', '/pharmacy/orders', '/pharmacy/orders/:orderId'],
    4: ['/pharmacy/profile', '/pharmacy/manufacturers', '/pharmacy/add-orders', '/pharmacy/orders', '/pharmacy/orders/:orderId']
  };

  const allowedRoutes = statusAccessMap[status] || [];
  
  const isAllowed = (route) => {
    // Matches exact routes hash map
    if (route === location.pathname) return true;

    // checks dynamic for order/:orderId routes
    const regexPattern = route.replace(/:[^\s/]+/g, '([\\w-]+)');
    const regex = new RegExp(`^${regexPattern}$`);

    return regex.test(location.pathname);
  };

  const routeAllowed = allowedRoutes.some(isAllowed);

  if (!routeAllowed) {
    return <Navigate to="/403" />;
  }

  return <Outlet />;
};

export default StatusAccessControl;