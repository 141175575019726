import React, { useContext } from 'react';
import { AuthenticationContext } from '../../Auth/authentication.context';
import { useNavigate } from 'react-router-dom'

function Banner() {
  const {bannerMsg, status, user} = useContext(AuthenticationContext)
  const navigate = useNavigate();

  const setBannerButton = (context) => {
    switch (status) {
      case 0:
        return context === "navigation" ? "/pharmacy/profile" : "To Profile"
      case 1:
        return context === "navigation"? "/pharmacy/manufacturers" : "To Manufacturer" 
      case 2: 
        return context === "navigation"? "/pharmacy/manufacturers" : "To Manufacturer"
      case 3: 
        return context === "navigation"? "/pharmacy/orders" : "To Orders"
      default:
        return context === "navigation"? "/pharmacy/profile" : "To Profile"
    } 
  }


  return (
    <div>
    {((status < 4) && (user.role === "pharmacy")) ?  
        <div  onClick={() => { navigate(setBannerButton("navigation")) }} className='profileCompletionBanner'>
        <div className='messageContainer'>
          <span className='mdi mdi-bell-outline icon mdi-shake'></span>
          <p className='message'>{bannerMsg}</p>
        </div>
        
        <div className='actionContainer'>
          <div className='actionText bg-transparent border-0 text-white'>
            {setBannerButton("text")}
          </div>
          <span className='mdi mdi-arrow-right'></span>
        </div> 
      </div>: null}
    </div>
  )
}

export default Banner
