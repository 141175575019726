import React, { useState, useContext, useEffect } from 'react'
import { Row, Col, Card, CardBody, Label, Button, Spinner } from 'reactstrap'
import PlacesAutocomplete from '../shared/PlacesAutoComplete'
import { AuthenticationContext } from '../../Auth/authentication.context'
import { Checkbox } from 'primereact/checkbox'

const PharmacyProfileDetails = ({ user, reloadData, loading }) => {
  const [ownerPhone, setOwnerPhone] = useState(
    user?.pharmacy?.pharmacyOwnerTelephone || ''
  )
  const [ownerEmail, setOwnerEmail] = useState(
    user?.pharmacy?.pharmacyOwnerEmail || ''
  )
  const [editDisplayName, setEditDisplayName] = useState(user?.displayName)
  const [editMode, setEditMode] = useState(false)
  const [editName, setEditName] = useState(user?.displayName)
  const [editPharmacyName, setEditPharmacyName] = useState(user?.pharmacyName)
  const [editTelephone, setEditTelephone] = useState('')
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [userLoading, setUserLoading] = useState(false)
  const [isOwnerContactDifferent, setIsOwnerContactDifferent] = useState(false)
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null)
  const [editTradeName, setEditTradeName] = useState('')
  const [editPharmacyOwnerName, setEditPharmacyOwnerName] = useState('')
  const [editFax, setEditFax] = useState('')
  const [isPharmacistInfoDifferent, setIsPharmacistInfoDifferent] =
    useState(false)

  const { onProfileUpdate, success } = useContext(AuthenticationContext)
  const [systemsEditData, setSystemEditArray] = useState([])

  const [originsDirectModule, setOriginsDirectModule] = useState(null)
  const [originsManagementModule, setOriginsManagementModule] = useState(null)
  const [systemsLoading, setSystemsLoading] = useState(false)

  useEffect(() => {
    const systemsData =
      user?.pharmacyModules?.map((module) => module.name) || []
    setSystemEditArray(systemsData)

    const originsDirect = systemsData.includes('Origins Direct')
    setOriginsDirectModule(originsDirect)

    const originsManagement = systemsData.includes('Origins Management')
    setOriginsManagementModule(originsManagement)
  }, [user])

  const onSystemChange = (e, moduleName) => {
    const isChecked = e.checked

    if (moduleName === 'Origins Direct') {
      setOriginsDirectModule(isChecked)
    } else if (moduleName === 'Origins Management') {
      setOriginsManagementModule(isChecked)
    }

    let updatedSystemsEditData = [...systemsEditData]

    if (isChecked && !updatedSystemsEditData.includes(moduleName)) {
      updatedSystemsEditData.push(moduleName)
    } else if (!isChecked && updatedSystemsEditData.includes(moduleName)) {
      updatedSystemsEditData = updatedSystemsEditData.filter(
        (name) => name !== moduleName
      )
    }

    setSystemEditArray(updatedSystemsEditData)
  }

  const handleEditSubmit = () => {
    // onProfileUpdate(editName, editPharmacyName, selectedAddress, user.id)
    const updatedPharmacyOwnerTelephone = isOwnerContactDifferent
      ? ownerPhone
      : null

    const updatedPharmacyOwnerEmail = isOwnerContactDifferent
      ? ownerEmail
      : null

    onProfileUpdate(
      editDisplayName,
      editName,
      editTradeName,
      editPharmacyName,
      selectedAddress,
      editTelephone,
      editFax,
      selectedShippingAddress,
      editPharmacyOwnerName,
      updatedPharmacyOwnerTelephone,
      updatedPharmacyOwnerEmail,
      user?.id // Pass the user ID if available
    )

    // setToastStatus({
    //   toastStatus: 'success',
    //   msg: 'Profile updated successfully!',
    // })
    setEditMode(false)
    setUserLoading(true)
  }

  const handleSystemEditSubmit = () => {
    const updatedPharmacyOwnerTelephone = isOwnerContactDifferent
      ? ownerPhone
      : null

    const updatedPharmacyOwnerEmail = isOwnerContactDifferent
      ? ownerEmail
      : null

    onProfileUpdate(
      editDisplayName,
      editName,
      editTradeName,
      editPharmacyName,
      selectedAddress,
      editTelephone,
      editFax,
      selectedShippingAddress,
      editPharmacyOwnerName,
      updatedPharmacyOwnerTelephone,
      updatedPharmacyOwnerEmail,
      user?.id,
      systemsEditData
    )
    setEditMode(false)
    setSystemsLoading(true)
  }
  const handleOwnerContactChange = (e) => {
    setIsOwnerContactDifferent(e.checked)
    if (!e.checked) {
      // If checkbox is unchecked, clear the owner phone and email
      setOwnerPhone('')
      setOwnerEmail('')
    }
  }

  const pharmacyModules = user?.pharmacyModules.map((module, index) => (
    <span
      className='badge bg-primary rounded-pill text-light py-2 px-3 fw-bold mx-1'
      key={index}
    >
      {module.name}
    </span>
  ))

  useEffect(() => {
    if (success === true) {
      reloadData()
      setUserLoading(false)
      setSystemsLoading(false)
    }
  }, [success])
  useEffect(() => {
    if (loading === false) {
      setUserLoading(false)
    }
  }, [loading])
  return (
    <>
      <div className='text-end mb-3 '>
        <Button
          type='submit'
          color='primary'
          onClick={() => setEditMode((prevState) => !prevState)}
        >
          {editMode ? 'Back' : 'Edit Pharmacy'}
        </Button>
      </div>

      <Row>
        <Col lg='12'>
          <Card>
            <CardBody>
              <div className='d-flex'>
                <div className='row row-cols-2 w-100'>
                  <div className='text-muted'>
                    <h4 className='mb-2'>{user?.pharmacy?.legalName}</h4>
                    <h6 className='mb-1'>Pharmacy</h6>
                  </div>
                  <div className='text-muted'>
                    <h4 className='mb-2'>{user?.accountNo}</h4>
                    <h6 className='mb-1'>Account Number</h6>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Card>
        <CardBody>
          {userLoading ? (
            <div className='d-flex justify-content-center align-items-center '>
              <Spinner color='primary' />
            </div>
          ) : (
            <>
              {!editMode && (
                <div className='row row-cols-2'>
                  <div className='mb-3'>
                    <h4 style={{ color: '#626ED4' }} className='mt-2 mb-2'>
                      Pharmacy Information
                    </h4>
                  </div>
                  <div></div>
                  <div className='form-group col '>
                    <Label className='form-label'>Legal Name</Label>
                    <p>{user?.pharmacy?.legalName}</p>
                  </div>
                  <div className='form-group col'>
                    <Label className='form-label mt-2'>Trade Name</Label>
                    <p>{user?.pharmacy?.tradeName}</p>
                  </div>
                  <div className='form-group col '>
                    <Label className='form-label mt-2'>Email</Label>
                    <p>{user?.email}</p>
                  </div>

                  <div className='form-group col '>
                    <Label className='form-label mt-2'>Address</Label>
                    <p>
                      {user?.address ? user?.address : 'There is no address'}
                    </p>
                  </div>
                  <div className='form-group col '>
                    <Label className='form-label mt-2'>Telephone Number</Label>
                    <p>
                      {user?.pharmacy?.telephone
                        ? user?.pharmacy?.telephone
                        : 'There is no Telephone Number'}
                    </p>
                  </div>
                  <div className='form-group col '>
                    <Label className='form-label mt-2'>Fax</Label>
                    <p>
                      {user?.pharmacy?.fax
                        ? user?.pharmacy?.fax
                        : 'There is no fax'}
                    </p>
                  </div>
                  <div className='form-group col '>
                    <Label className='form-label mt-2'>Shipping Address</Label>
                    <p>
                      {user?.pharmacy?.shippingAddress
                        ? user?.pharmacy?.shippingAddress
                        : 'There is no shipping address'}
                    </p>
                  </div>
                  <div></div>
                  <>
                    <h4 style={{ color: '#626ED4' }} className='mt-4 mb-3'>
                      Pharmacy Owner Information
                    </h4>
                  </>
                  <div></div>

                  <div className='form-group col '>
                    <Label className='form-label mt-2'>
                      Pharmacy Owner Name
                    </Label>
                    <p>
                      {user?.pharmacy?.pharmacyOwnerName
                        ? user?.pharmacy?.pharmacyOwnerName
                        : 'There is no pharmacy owner name'}
                    </p>
                  </div>
                  <div className='form-group col '>
                    <Label className='form-label mt-2'>
                      Pharmacy Owner Telephone
                    </Label>
                    <p>
                      {user?.pharmacy?.pharmacyOwnerTelephone
                        ? user?.pharmacy?.pharmacyOwnerTelephone
                        : 'There is no pharmacy owner telephone'}
                    </p>
                  </div>
                  <div className='form-group col '>
                    <Label className='form-label mt-2'>Email</Label>
                    <p>
                      {user?.pharmacy?.pharmacyOwnerEmail
                        ? user?.pharmacy?.pharmacyOwnerEmail
                        : 'There is no address'}
                    </p>
                  </div>
                </div>
              )}
              {editMode && (
                <div>
                  <h4 className='card-title mb-4'>Edit Pharmacy</h4>

                  <Card>
                    <CardBody>
                      <form className=''>
                        <h4 style={{ color: '#626ED4' }} className='mt-2 mb-2'>
                          Pharmacy Information
                        </h4>
                        <div className='row row-cols-1 row-cols-sm-2'>
                          {/* <div className='form-group col '>
                            <Label className='form-label mt-3'>Full Name</Label>
                            <input
                              name='fullName'
                              className='form-control'
                              type='text'
                              placeholder={user.displayName}
                              onChange={(e) => setEditDisplayName(e.target.value)}
                            />
                          </div> */}

                          <div className='form-group col'>
                            <Label className='form-label mt-3'>
                              Legal Name
                            </Label>
                            <input
                              name='legalName'
                              className='form-control'
                              type='text'
                              placeholder={user?.pharmacy?.legalName}
                              onChange={(e) => setEditName(e.target.value)}
                            />
                          </div>

                          {/* --------------------------- */}

                          <div className='form-group col'>
                            <Label className='form-label mt-3'>
                              Trade Name
                            </Label>
                            <input
                              name='tradeName'
                              className='form-control'
                              type='text'
                              placeholder={user?.pharmacy?.tradeName}
                              onChange={(e) => setEditTradeName(e.target.value)}
                            />
                          </div>

                          {/* --------------------------- */}
                          <div className='form-group col'>
                            <Label className='form-label mt-3'>
                              Pharmacy Name
                            </Label>
                            <input
                              name='PharmacyName'
                              className='form-control'
                              placeholder={user.pharmacyName}
                              type='text'
                              onChange={(e) => {
                                setEditPharmacyName(e.target.value)
                              }}
                            />
                          </div>

                          <div className='form-group col'>
                            <Label className='form-label mt-3'>Email</Label>
                            <input
                              name='UserEmail'
                              className='form-control'
                              placeholder={user.email}
                              type='text'
                              disabled={true}
                            />
                          </div>

                          <div className='form-group col'>
                            <Label className='form-label mt-3'>Address</Label>
                            <input
                              name='tradeName'
                              className='form-control'
                              type='text'
                              placeholder={user.address}
                              disabled={true}
                            />
                          </div>

                          <div className='row'>
                            <div className='form-group col'>
                              <Label className='form-label mt-3'>
                                Shipping Address
                              </Label>
                              <PlacesAutocomplete
                                placeholder={user.shippingAddress}
                                setSelectedAddress={(e) =>
                                  setSelectedShippingAddress(e)
                                }
                              />
                            </div>
                          </div>

                          <div className='form-group col'>
                            <Label className='form-label mt-3'>
                              Telephone Number
                            </Label>
                            <input
                              name='telephone'
                              className='form-control'
                              type='text'
                              placeholder={user?.pharmacy?.telephone}
                              onChange={(e) => setEditTelephone(e.target.value)}
                            />
                          </div>

                          <div className='form-group col'>
                            <Label className='form-label mt-3'>
                              Fax Number
                            </Label>
                            <input
                              name='fax'
                              className='form-control'
                              type='text'
                              placeholder={user?.pharmacy?.fax}
                              onChange={(e) => setEditFax(e.target.value)}
                            />
                          </div>
                        </div>

                        <>
                          <h4
                            style={{ color: '#626ED4' }}
                            className='mt-4 mb-2'
                          >
                            Pharmacy Owner Information
                          </h4>
                          <div></div>
                        </>

                        <div className='row'>
                          <div className='form-group col'>
                            <Label className='form-label mt-3'>
                              Pharmacy Owner Name
                            </Label>
                            <input
                              name='pharmacyOwnerName'
                              className='form-control'
                              type='text'
                              placeholder={
                                user?.pharmacy?.pharmacyOwnerName
                                  ? user?.pharmacy?.pharmacyOwnerName
                                  : 'Enter Pharmacy Owner Name'
                              }
                              onChange={(e) =>
                                setEditPharmacyOwnerName(e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className='row'>
                          <div className='form-group col'>
                            <div className='d-flex align-items-center mt-4 mb-1'>
                              <Checkbox
                                inputId='originsDirect'
                                name='Origins Direct'
                                value='Origins Direct'
                                checked={isOwnerContactDifferent}
                                onChange={handleOwnerContactChange}
                              />
                              <label
                                htmlFor='originsDirect'
                                className='mx-2 mb-0'
                              >
                                Phone and email are different
                              </label>
                            </div>
                          </div>
                        </div>

                        {isOwnerContactDifferent && (
                          <>
                            <div className='row row-cols-1 row-cols-sm-2'>
                              <div className='form-group col'>
                                <Label className='form-label mt-3'>
                                  Telephone Number
                                </Label>
                                <input
                                  name='pharmacyOwnerTelephone'
                                  className='form-control'
                                  type='text'
                                  placeholder={
                                    user?.pharmacyOwnerTelephone
                                      ? user?.pharmacyOwnerTelephone
                                      : 'Enter Telephone Number'
                                  }
                                  value={ownerPhone || ''}
                                  onChange={(e) =>
                                    setOwnerPhone(e.target.value)
                                  }
                                />
                              </div>

                              {/* --------------------------- */}
                              <div className='form-group col'>
                                <Label className='form-label mt-3'>Email</Label>
                                <input
                                  name='pharmacyOwnerEmail'
                                  className='form-control'
                                  type='text'
                                  placeholder={
                                    user?.pharmacyOwnerEmail
                                      ? user?.pharmacyOwnerEmail
                                      : 'Enter Email'
                                  }
                                  value={ownerEmail || ''}
                                  onChange={(e) =>
                                    setOwnerEmail(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <div className='text-center mt-4 d-flex justify-content-end gap-3 col-12'>
                          <Button
                            color=''
                            className='btn btn-outline-primary'
                            onClick={() => setEditMode(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            type='submit'
                            color='primary'
                            onClick={(e) => {
                              e.preventDefault()
                              handleEditSubmit()
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      </form>
                    </CardBody>
                  </Card>
                  {/* </CardBody> */}
                  {/* </Card> */}
                </div>
              )}
              <div className='col col-sm-6  col-lg-4 mt-3 '></div>
            </>
          )}
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          {systemsLoading ? (
            <div className='d-flex justify-content-center align-items-center '>
              <Spinner color='primary' />
            </div>
          ) : (
            <>
              {!editMode && (
                <div className='row row-cols-2'>
                  <div className='form-group col '>
                    <Label className='form-label'>Systems</Label>
                    <h4 className='m-0'>{pharmacyModules}</h4>
                  </div>
                </div>
              )}

              {editMode && (
                <div>
                  <h4 className='card-title mb-4'>Edit Systems</h4>

                  {/* <Card> */}
                  {/* <CardBody> */}
                  <form className='row row-cols-3'>
                    <div className='form-group col'>
                      <div className='d-flex align-items-center'>
                        <Checkbox
                          inputId='originsDirect'
                          name='Origins Direct'
                          value='Origins Direct'
                          onChange={(e) => onSystemChange(e, 'Origins Direct')}
                          checked={originsDirectModule}
                        />
                        <label htmlFor='originsDirect' className='mx-2 mb-0'>
                          Origins Direct
                        </label>
                      </div>
                    </div>

                    {/* --------------------------- */}

                    <div className='form-group col'>
                      <div className='d-flex align-items-center'>
                        <Checkbox
                          inputId=' originsManagement'
                          name=' Origins Management'
                          value='Origins Management'
                          onChange={(e) =>
                            onSystemChange(e, 'Origins Management')
                          }
                          checked={originsManagementModule}
                        />
                        <label
                          htmlFor='originsManagement'
                          className='mx-2 mb-0'
                        >
                          Origins Management
                        </label>
                      </div>
                    </div>

                    <div className='text-center mt-4 d-flex justify-content-end gap-3 col-12'>
                      <Button
                        color=''
                        className='btn btn-outline-primary'
                        onClick={() => setEditMode(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type='submit'
                        color='primary'
                        onClick={(e) => {
                          e.preventDefault()
                          handleSystemEditSubmit()
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </form>
                </div>
              )}
            </>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default PharmacyProfileDetails
