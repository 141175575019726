import React, { useContext, useState, useRef, useEffect } from 'react'
import Modals from '../../shared/Modals'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { formatCurrency } from '../../shared/utils'
import server from '../../../server'
import { ToastPopUp } from '../../shared/utils'
import { Toast } from 'primereact/toast'
import { AuthenticationContext } from '../../../Auth/authentication.context'
const OrderSummary = ({
  visible,
  setVisible,
  orderData,
  total,
  subtotal,
  handleSuccess,
  ordableProducts,
}) => {
  const { status, setStatus, setBannerMsg } = useContext(AuthenticationContext)

  const [data, setData] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [check, setCheck] = useState(true)
  const [loading, setLoading] = useState(false)

  const [state, setState] = useState({
    first: 0,
    rows: 10,
    page: 0,
  })
  const toast = useRef(null)
  useEffect(() => {
    const updatedData = orderData.map((item) => {
      const gpMinOrderPrice = item?.manufacturer?.gpMinOrderPrice || 0
      const totalAmount = parseFloat(item.subtotal)

      // Add a new key "confirmed" based on the condition
      const confirmed = gpMinOrderPrice > totalAmount ? false : true

      return {
        ...item,
        confirmed,
      }
    })

    setData(updatedData)
    const hasFalseConfirmation = updatedData.some(
      (item) => item.confirmed === false
    )
  }, [orderData])

  const manufacturerTotals = data.reduce((totals, item) => {
    const manufacturer = item?.manufacturer?.name
    const subtotal = parseFloat(item.subtotal)
    const gpMinOrderPrice = item?.manufacturer?.gpMinOrderPrice || 0

    if (!totals[manufacturer]) {
      totals[manufacturer] = { TotalAmount: 0, gpMinOrderPrice: 0 }
    }

    const leadTime = item?.manufacturer.leadTime
    const cutOffTime = item?.manufacturer.cutOffTime
    let currentTime = new Date().toLocaleTimeString([], {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    })

    const calculateDelivery = () => {
      let deliveryTime

      if (cutOffTime > currentTime) {
        deliveryTime = leadTime
      } else {
        deliveryTime = leadTime + 1
      }

      if (deliveryTime === 0) {
        return 'Today'
      } else {
        return `${deliveryTime} Working days`
      }
    }

    totals[manufacturer].TotalAmount += subtotal
    totals[manufacturer].gpMinOrderPrice = gpMinOrderPrice
    totals[manufacturer].deliveryTime = calculateDelivery()
    totals[manufacturer].confirmed = item?.confirmed

    return totals
  }, {})

  const manufacturersData = Object.keys(manufacturerTotals).map(
    (manufacturer) => ({
      Manufacturer: manufacturer,
      TotalAmount: manufacturerTotals[manufacturer].TotalAmount,
      gpMinOrderPrice: manufacturerTotals[manufacturer].gpMinOrderPrice,
      deliveryTime: manufacturerTotals[manufacturer].deliveryTime,
      confirmed:
        manufacturerTotals[manufacturer].TotalAmount >
        manufacturerTotals[manufacturer].gpMinOrderPrice
          ? true
          : false,
    })
  )

  useEffect(() => {
    const anyConfirmed = manufacturersData.some(
      (item) => item.confirmed === true
    )
    setCheck(anyConfirmed)
  }, [manufacturersData])

  const modalBtnName = check ? 'CONFIRM ORDER' : 'EDIT ORDER'
  const handleOrder = () => {
    if (check) {
      const filteredData = data.filter((item) =>
        manufacturersData.some(
          (manufacturer) =>
            manufacturer.Manufacturer === item.itemGroupMajorDescription &&
            manufacturer.confirmed
        )
      )
      // Map filtered data to orderDetails
      const orderDetails = filteredData.map((item) => ({
        productId: item.id,
        quantity: item.quantity,
      }))

      setLoading(true)
      server
        .post(
          `/invoicesHeaders/pharmacy`,
          {
            details: orderDetails,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        )
        .then((response) => {
          setLoading(false)
          setVisible(false)
          ordableProducts(orderDetails)
          setStatus(response?.data?.userBanner?.status)
          setBannerMsg(response?.data?.userBanner?.message)
          handleSuccess()
        })
        .catch((e) => {
          setLoading(false)
          setVisible(false)
          ToastPopUp(
            toast,
            'error',
            'Error Your order has been Failed ',
            e.response.data.message
          )
        })
    } else {
      setVisible(false)
    }
  }

  return (
    <>
      {' '}
      <div className='card flex justify-content-center'>
        <Toast ref={toast} />
      </div>
      <Modals
        btnName={modalBtnName}
        ModalHeader={'Order Summary'}
        visible={visible}
        setVisible={setVisible}
        onButtonClick={handleOrder}
        loading={loading}
        wait={true}
      >
        <DataTable
          value={manufacturersData}
          paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginator
          showGridlines
          loading={tableLoading}
          dataKey={manufacturersData.Manufacturer}
          removableSort
          emptyMessage='No orders to display'
          rows={state.rows}
          first={state.first}
          onPage={(e) => setState(e)}
          onSort={(e) => {
            e.first = 0
            setState(e)
          }}
          onFilter={(e) => setState(e)}
        >
          <Column
            field='Manufacturer'
            header='Manufacturer'
            style={{ fontWeight: 'bold' }}
          />
          <Column
            field='TotalAmount'
            header='Total Amount'
            body={(rowData) => formatCurrency(rowData.TotalAmount)}
          />
          <Column
            field='gpMinOrderPrice'
            header='Minimum Order'
            body={(rowData) => formatCurrency(rowData.gpMinOrderPrice)}
          />
          <Column
            // field='gpMinOrderPrice'
            header='Meets Min Order'
            body={(rowData) =>
              rowData.gpMinOrderPrice > rowData.TotalAmount ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p
                    style={{
                      border: '1px solid #fee2e2',
                      borderRadius: '20px',
                      width: '80%',
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: '#fee2e2',
                      color: '#991b1b',
                      margin: '0px',
                    }}
                  >
                    No
                  </p>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p
                    style={{
                      border: '1px solid #dcfce7',
                      borderRadius: '20px',
                      width: '80%',
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: '#dcfce7',
                      color: '#166534',
                      margin: '0px',
                    }}
                  >
                    Yes
                  </p>
                </div>
              )
            }
          />
          <Column field='deliveryTime' header='Expected Delivery Time' />
        </DataTable>
        <div className='d-flex flex-column align-items-center mt-5'>
          <div className='row justify-content-center w-100'>
            <p className='fw-bold col-2 px-0'> Subtotal</p>
            <p className='fw-bold col-2 px-0'> {subtotal}</p>
          </div>
          <div className='row justify-content-center w-100'>
            <p className='fw-bold col-2 px-0'>TOTAL</p>
            <p className='fw-bold col-2 px-0'>{total}</p>
          </div>
        </div>
      </Modals>
    </>
  )
}

export default OrderSummary
