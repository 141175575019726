import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react'
import { classNames } from 'primereact/utils'
import { Dialog } from 'primereact/dialog'
import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { Checkbox } from 'primereact/checkbox'
import { useDropzone } from 'react-dropzone'

import server from '../../server'
import { ToastPopUp } from '../shared/utils'
import { formatDate } from '../shared/utils'

const AddEditAds = forwardRef((props, ref) => {
  const { AddEditRef } = ref
  const { advertisement, setAdvertisement, emptyAdvertisement } = props
  const [advertisementDialog, setAdvertisementDialog] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [isAdvertisementEdit, setIsAdvertisementEdit] = useState(false)
  const toast = useRef(null)

  useImperativeHandle(AddEditRef, () => ({
    openNew() {
      setSubmitted(false)
      setAdvertisementDialog(true)
      setAdvertisement(emptyAdvertisement) // Ensure this is correctly set
    },

    editAdvertisement(advertisement) {
      setSubmitted(false)
      setIsAdvertisementEdit(true)
      setAdvertisementDialog(true)
      setAdvertisement(advertisement) // Set the current advertisement to edit
    },
  }))

  const hideDialog = () => {
    setAdvertisementDialog(false)
    setIsAdvertisementEdit(false)
  }

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || ''
    let _advertisement = { ...advertisement }
    _advertisement[`${name}`] = val
    setAdvertisement(_advertisement)
  }

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0]
      const reader = new FileReader()

      reader.onloadend = function () {
        const base64data = reader.result

        setAdvertisement((prevAdvertisement) => ({
          ...prevAdvertisement,
          file: base64data,
        }))

        ToastPopUp(
          toast,
          'success',
          'Image uploaded',
          'Image has been uploaded successfully.'
        )
      }

      reader.readAsDataURL(file)
    },
  })

  const saveAdvertisement = () => {
    if (advertisement?.title && advertisement?.link && advertisement?.message) {
      const formData = new FormData()

      formData.append('title', advertisement.title)
      formData.append('link', advertisement.link)
      formData.append('message', advertisement.message)
      formData.append('expiryDate', formatDate(advertisement.expiryDate))
      formData.append('active', advertisement.active ? 'true' : 'false')
      if (advertisement.file) {
        const blob = dataURItoBlob(advertisement.file)
        formData.append('file', blob, 'image.png')
      }

      const requestUrl = isAdvertisementEdit
        ? `/ads/${advertisement?.id}`
        : '/ads/'
      const requestMethod = isAdvertisementEdit ? 'PATCH' : 'POST'

      server({
        url: requestUrl,
        method: requestMethod,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
        .then((response) => {
          props.getData()
          ToastPopUp(
            toast,
            'success',
            isAdvertisementEdit
              ? 'Advertisement Updated'
              : 'Advertisement Added',
            isAdvertisementEdit
              ? 'Advertisement has been updated successfully.'
              : 'Advertisement has been added successfully.'
          )
          setIsAdvertisementEdit(false)
          setSubmitted(true)
        })
        .catch((e) => {
          ToastPopUp(toast, 'error', 'Error', e.response.data.message)
          setIsAdvertisementEdit(false)
          setSubmitted(true)
        })

      setAdvertisementDialog(false)
      setAdvertisement(emptyAdvertisement)
    } else {
      setSubmitted(true)
    }
  }

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1])
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const arrayBuffer = new ArrayBuffer(byteString.length)
    const uint8Array = new Uint8Array(arrayBuffer)

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i)
    }

    return new Blob([uint8Array], { type: mimeString })
  }

  const advertisementDialogFooter = (
    <React.Fragment>
      <Button label='Cancel' icon='pi pi-times' outlined onClick={hideDialog} />
      <Button
        label={
          isAdvertisementEdit ? 'Update Advertisement' : 'Add Advertisement'
        }
        icon='pi pi-check'
        onClick={saveAdvertisement}
      />
    </React.Fragment>
  )
  const onStatusChange = (e) => {
    setAdvertisement((prevAdvertisement) => ({
      ...prevAdvertisement,
      active: e.checked ? true : false, // Ensure it sends false when unchecked
    }))
  }
  const removeImage = () => {
    setAdvertisement((prevAdvertisement) => ({
      ...prevAdvertisement,
      file: null,
    }))

    ToastPopUp(toast, 'info', 'Image removed', 'Image has been removed.')
  }
  return (
    <>
      <div className='card flex justify-content-center'>
        <Toast ref={toast} />
      </div>
      <Dialog
        visible={advertisementDialog}
        style={{ width: '40rem' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        header={
          isAdvertisementEdit ? 'Update Advertisement' : 'Add Advertisement'
        }
        modal
        className='p-fluid'
        footer={advertisementDialogFooter}
        onHide={hideDialog}
      >
        <div className='field'>
          <label htmlFor='title' className='font-bold'>
            Title
          </label>
          <InputText
            id='title'
            autoFocus
            onChange={(e) => onInputChange(e, 'title')}
            value={advertisement?.title || ''} // Default to an empty string if null
            placeholder='Enter Title'
            required
          />
        </div>
        <div className='field mt-4'>
          <label htmlFor='link' className='font-bold'>
            Link
          </label>
          <InputText
            id='link'
            onChange={(e) => onInputChange(e, 'link')}
            placeholder='Enter Link'
            required
            value={advertisement?.link || ''} // Default to an empty string if null
          />
        </div>
        <div className='field mt-4'>
          <label htmlFor='expiryDate' className='font-bold'>
            Expiry Date
          </label>
          <Calendar
            id='expiryDate'
            value={
              advertisement?.expiryDate
                ? new Date(advertisement?.expiryDate)
                : null
            } // Ensure it is a valid Date object
            onChange={(e) => onInputChange(e, 'expiryDate')}
            dateFormat='yy-mm-dd'
            placeholder='Select Expiry Date'
            showIcon
            showButtonBar
            minDate={new Date()}
          />
        </div>
        <div className='field mt-4'>
          <label htmlFor='message' className='font-bold'>
            Message
          </label>
          <InputText
            id='message'
            onChange={(e) => onInputChange(e, 'message')}
            value={advertisement?.message || ''} // Default to an empty string if null
            placeholder='Enter Message'
            required
          />
        </div>
        <div className='d-flex align-items-center mt-4 mb-1'>
          <Checkbox
            inputId='active'
            name='active'
            checked={advertisement?.active}
            value={advertisement?.active}
            onChange={onStatusChange}
          />
          <label htmlFor='active' className='mx-2 mb-0'>
            Active
          </label>
        </div>
        <div className='field mt-4'>
          {/* <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
      

            {advertisement?.file || advertisement?.image ? (
              <div className='d-flex justify-content-between'>
                <img
                  src={
                    advertisement.file
                      ? `data:image/png;base64,${advertisement?.file}`
                      : advertisement?.image
                  }
                  alt='Preview'
                  style={{ maxWidth: '200px', margin: '10px' }}
                />
                <Button
                  icon='pi pi-times'
                  className='bg-white text-danger border-0 mt-2'
                  onClick={removeImage}
                />
              </div>
            ) : (
              <p className='m-2'>
                Drag and drop files here, or click to select files
              </p>
            )}
          </div> */}

          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />

            {advertisement?.file || advertisement?.image ? (
              <div className='d-flex justify-content-between'>
                <img
                  src={
                    advertisement.file
                      ? advertisement.file
                      : advertisement.image
                  }
                  alt='Preview'
                  style={{ maxWidth: '200px', margin: '10px' }}
                />
                <Button
                  icon='pi pi-times'
                  className='bg-white text-danger border-0 mt-2'
                  onClick={removeImage}
                />
              </div>
            ) : (
              <p className='m-2'>
                Drag and drop files here, or click to select files
              </p>
            )}
          </div>

          {/* {submitted && !advertisement.image && (
              <small className='p-error'>Image is required.</small>
            )} */}
        </div>
      </Dialog>
    </>
  )
})

export default AddEditAds
