import { createBrowserRouter } from 'react-router-dom'
import MainLayout from '../components/Layout'
import Login from '../components/Authentication/Login'
import Register from '../components/Authentication/Register'

import PrivateRoutes from '../Routes/PrivateRoutes'
import UserProfile from '../components/Authentication/UserProfile'
import Pages404 from '../pages/utility/pages-404'
import Pages403 from '../pages/utility/pages-403'
import Logout from '../components/Authentication/Logout'
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy'
import ForgetPasswordPage from '../components/Authentication/ForgetPassword'
import Dashboard from '../components/Dashboard'
import OrderDetails from '../components/orders/OrderDetails'
import CreatePassword from '../components/Authentication/CreatePassword'
import ChangePassword from '../components/Authentication/ChangePassword'
import AdminOrders from '../components/orders/AdminOrders'
import MoleculesTable from '../components/Reports/Molecules/MoleculesTable'
import PharmacyProfile from '../components/Authentication/PharmacyProfile'
import PharmaciesTable from '../components/Pharmacies/PharmaciesTable'
import AdminsTable from '../components/Administrators/AdminsTable'
import PharmacyOrders from '../components/orders/PharmacyOrders/PharmacyOrders'
import MoleculesReport from '../components/Reports/MoleculesReport'
import MoleculeHistory from '../components/Reports/Molecules/MoleculeHistory'
import ManufacturersAdmin from '../components/Reports/Manufacturers/ManufacturersAdmin'
import ManufacturersPharmacy from '../components/Reports/Manufacturers/ManufacturersPharmacy'
import AdminUploadFile from '../components/Upload/AdminUploadFile'
import AddOrders from '../components/AddOrders/AddOrders'
import ManufacturersTable from '../components/Reports/Manufacturers/ManufacturersTable'
import ManufacturersUsers from '../components/ManufacturersUsers'
import ManufacturerOrders from '../components/ManufacturerOrders/ManufacturerOrders'
import ManufacturerOrderDetails from '../components/ManufacturerOrders/ManufacturerOrderDetails'
import AdminPrivate from './AdminPrivate'
import ManufacturersPrivate from './ManufacturersPrivate'
import PharmacyPrivate from './PharmacyPrivate'
import LoginManufacturer from '../components/Authentication/LoginManufacturer'
import LoginAdmin from '../components/Authentication/LoginAdmin.js'
import AdsControlPanel from '../components/AdsControlPanel/AdsControlPanel.js'
import Module from './Module'
import StatusAccessControl from './StatusAccessControl.js'
import ManufacturerApproval from '../components/MailActions/ManufacturerApproval.js'
import ManufacturerRejection from '../components/MailActions/ManufacturerRejection.js'
import OrderApproval from '../components/MailActions/OrderApproval.js'
import OrderRejection from '../components/MailActions/OrderRejection.js'
import Responded from '../components/MailActions/Responeded.js'
export const router = createBrowserRouter([
  {
    path: '/',
    element: <PrivateRoutes />,
    errorElement: <Pages404 />,
    children: [
      {
        path: '',
        element: <MainLayout />,
        children: [
          { index: true, element: <Dashboard /> },

          {
            path: 'pharmacy',
            element: <PharmacyPrivate />,
            children: [
              {
                index: true,
                element: <Dashboard />,
              },
              {
                path: 'profile',
                element: <StatusAccessControl />,
                children: [{ index: true, element: <UserProfile /> }],
              },
              {
                path: 'add-orders',
                element: <StatusAccessControl />,
                children: [
                  {
                    element: <Module />,
                    children: [
                      {
                        index: true,
                        element: <AddOrders />,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'orders',
                element: <StatusAccessControl />,
                children: [
                  { index: true, element: <PharmacyOrders /> },
                  { path: ':orderId', element: <OrderDetails /> },
                ],
              },
              {
                path: 'manufacturers',
                element: <StatusAccessControl />,
                children: [{ index: true, element: <ManufacturersPharmacy /> }],
              },
            ],
          },

          {
            path: 'admin',
            element: <AdminPrivate />,
            children: [
              {
                index: true,
                element: <Dashboard />,
              },
              {
                path: 'profile',
                element: <UserProfile />,
                children: [{ index: true, element: <UserProfile /> }],
              },
              {
                path: 'pharmacies',
                element: <PharmaciesTable />,
              },
              {
                path: 'reports/manufacturers',
                element: <ManufacturersAdmin />,
              },
              {
                path: 'pharmacies/:pharmacyId',
                element: <PharmacyProfile />,
              },
              {
                path: 'admins-list',
                element: <AdminsTable />,
              },

              {
                path: 'orders',
                element: <AdminOrders />,
              },
              {
                path: 'orders/:orderId',
                element: <OrderDetails />,
              },
              {
                path: 'molecules',
                element: <MoleculesTable />,
              },
              {
                path: 'manufacturers',
                element: <ManufacturersTable />,
              },
              {
                path: 'manufacturers-users',
                element: <ManufacturersUsers />,
              },
              {
                path: 'ads-control-panel',
                element: <AdsControlPanel />,
              },
              {
                path: 'upload-file',
                element: <AdminUploadFile />,
              },

              {
                path: 'reports',
                children: [
                  // {
                  //   index: true,
                  //   element: <GenerateReport />,
                  // },

                  {
                    path: 'molecules',
                    element: <MoleculesReport />,
                  },
                  {
                    path: 'molecules/:din',
                    element: <MoleculeHistory />,
                  },
                ],
              },
            ],
          },

          {
            path: 'manufacturer',
            element: <ManufacturersPrivate />,
            children: [
              {
                index: true,
                element: <Dashboard />,
              },
              {
                path: 'profile',
                element: <UserProfile />,
              },
              { path: 'manufacturerOrders', element: <ManufacturerOrders /> },
              {
                path: 'manufacturerOrders/:orderId',
                element: <ManufacturerOrderDetails />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/manufacturer-login',
    element: <LoginManufacturer />,
  },
  {
    path: '/admin-login',
    element: <LoginAdmin />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/create-password',
    element: <CreatePassword />,
  },
  {
    path: '/forget-password',
    element: <ForgetPasswordPage />,
  },
  {
    path: '/403',
    element: <Pages403 />,
  },
  {
    path: '/change-password',
    element: <ChangePassword />,
  },
  {
    path: '/logout',
    element: <Logout />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: 'pharmacy-account-approved',
    element: <ManufacturerApproval />,
  },
  {
    path: 'pharmacy-account-rejected',
    element: <ManufacturerRejection />,
  },
  {
    path: 'pharmacy-order-approved',
    element: <OrderApproval />,
  },
  {
    path: 'pharmacy-order-rejected',
    element: <OrderRejection />,
  },
  {
    path: 'already-responded',
    element: <Responded />,
  },
])
