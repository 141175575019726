import React from 'react'

const Approval = () => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='201'
        height='201'
        viewBox='0 0 201 201'
        fill='none'
      >
        <path
          d='M100.182 200.182C155.411 200.182 200.182 155.41 200.182 100.182C200.182 44.9532 155.411 0.181641 100.182 0.181641C44.9536 0.181641 0.182129 44.9532 0.182129 100.182C0.182129 155.41 44.9536 200.182 100.182 200.182Z'
          fill='#14AA4B'
        />
        <path
          d='M100.182 180.841C144.729 180.841 180.841 144.729 180.841 100.182C180.841 55.6355 144.729 19.5234 100.182 19.5234C55.6355 19.5234 19.5234 55.6355 19.5234 100.182C19.5234 144.729 55.6355 180.841 100.182 180.841Z'
          fill='#54B848'
        />
        <path
          d='M151.526 61.9799C146.687 57.1408 138.841 57.1408 134.002 61.9799L83.9402 112.044L66.3615 94.4661C61.5221 89.6273 53.6766 89.6274 48.8375 94.4664C43.9982 99.3058 43.9982 107.152 48.8375 111.991L74.2172 137.369C74.4986 137.719 74.7979 138.06 75.1231 138.385C78.1594 141.421 82.3786 142.55 86.2965 141.777C88.6572 141.33 90.9132 140.195 92.7402 138.368C92.9905 138.117 93.2201 137.854 93.4447 137.588L151.527 79.5038C156.366 74.6647 156.366 66.8189 151.526 61.9799Z'
          fill='white'
        />
      </svg>
    </>
  )
}

export default Approval
