import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'

import server from '../../../server'
import { useLocation, useNavigate } from 'react-router-dom'
import MainContentLayout from '../../Layout/MainContentLayout'

import {
  InputFieldTemplate,
  MultiSelectTemplate,
} from '../../shared/FilterTemplates'

// import ModifyMolecule from '../Molecules/ModifyMolecule'
import ModifyManufacture from './ModifyManufacturers'
import { Toast } from 'primereact/toast'
import { ToastPopUp } from '../../shared/utils'

export default function ManufacturersTable() {
  const [manufacturers, setManufacturers] = useState(null)
  const [selectedMolecule, setSelectedMolecule] = useState(null)
  const [totalRecords, setTotalRecords] = useState(0)
  const [tableLoading, setTableLoading] = useState(false)
  const [csvLoading, setcsvLoading] = useState(false)
  const [clearBtnDisabled, setClearBtnDisabled] = useState(true)
  const [lazyState, setLazyState] = useState({ rows: 10, page: 0, first: 0 })
  
  const initLazyState = () => {
    setLazyState((prevState) => ({
      ...prevState,
      sortField: '',
      sortOrder: '',
      filters: {
        name: { value: '' },
        source: { value: '' },
        drugIdentificationNo: { value: '' },
      },
    }))
  }

  const navigate = useNavigate()

  const dt = useRef()
  const toast = useRef()
  const { state } = useLocation()
  const AddEditRef = useRef()
  const DeleteRef = useRef()
  const refs = { AddEditRef, DeleteRef }

  const formatTimeTo12Hours = (timeString) => {
    const time = new Date(`1970-01-01T${timeString}`)
    return time.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
  }
  // Gets table data
  const getData = () => {
    const manufacturerName = lazyState?.filters?.name.value

    const requestParams =
      `?ordable=true` +
      `&page=${lazyState?.page ? lazyState.page + 1 : 1}` +
      `&limit=${lazyState?.rows}` +
      `${manufacturerName ? '&name=' + manufacturerName : ''}` +
      `${lazyState?.sortField ? '&sortByField=' + lazyState.sortField : ''}` +
      `${lazyState?.sortOrder ? '&sortByOrder=' + lazyState.sortOrder : ''}`

    setTableLoading(true)

    server
      .get(`/manufacturers/${requestParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        const manufacturers = response?.data?.Manufacturers

        const formattedManufacturers = manufacturers.map((manufacturer) => ({
          ...manufacturer,
          cutOffTime: formatTimeTo12Hours(manufacturer.cutOffTime),
        }))
        setManufacturers(formattedManufacturers)
        setTotalRecords(response.data.totalCount)
        setTableLoading(false)
        // setError(null)
      })
      .catch((error) => {
        setTableLoading(false)
        // setError(error.response.data.message)
      })
  }
  const exportCSV = () => {
    const manufacturerName = lazyState?.filters?.name.value

    const requestParams =
      `?ordable=true` +
      `${manufacturerName ? '&name=' + manufacturerName : ''}` +
      `${lazyState?.sortField ? '&sortByField=' + lazyState.sortField : ''}` +
      `${lazyState?.sortOrder ? '&sortByOrder=' + lazyState.sortOrder : ''}`
    setcsvLoading(true)
    server
      .get(`/manufacturers/export/${requestParams}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        responseType: 'blob',
      })
      .then((response) => {
        const blobData = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const url = window.URL.createObjectURL(blobData)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'manufacturers.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
        setcsvLoading(false)
      })
      .catch((error) => {
        setTableLoading(false)
        console.log('error', error)
      })
  }

  useEffect(() => {
    checkClearBtn()

    return getData()
  }, [lazyState])

  useEffect(() => initLazyState(), [])

  // Handles table page change
  const onPage = (event) => {
    setLazyState(event)
  }

  // Handles table sorting
  const onSort = (event) => {
    setLazyState(event)
  }

  // Handles table filtring
  const onFilter = (event) => {
    setLazyState(event)
  }

  // Enable and disable clear button according to filtring and sorting
  const checkClearBtn = () => {
    if (lazyState?.sortField || lazyState?.sortOrder) {
      setClearBtnDisabled(false)
      return
    }
    for (const key in lazyState?.filters) {
      if (lazyState?.filters[key].value) {
        setClearBtnDisabled(false)
        return
      }
    }
    setClearBtnDisabled(true)
  }

  // Filter TEMPLATES
  const manufacturerNameFilterElement = (options) => (
    <InputFieldTemplate
      state={options.value}
      stateHandler={(e) => options.filterCallback(e, options.index)}
      placeholder='Manufacturer name'
    />
  )

  // (Edit - Delete) column body template
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className='d-flex justify-content-center gap-2'>
          <Button
            icon='pi pi-pencil'
            rounded
            outlined
            className='mr-2'
            onClick={() => AddEditRef.current.editManufacturer(rowData)}
          />

          <Button
            icon='pi pi-trash'
            rounded
            outlined
            severity='danger'
            onClick={() => DeleteRef.current.confirmDeleteManufacturer(rowData)}
          />
        </div>
      </React.Fragment>
    )
  }
  const statusTemplate = (rowData) => {
    return (
      <>
        <span>{rowData.comingSoon ? 'Coming Soon' : 'Available'}</span>
      </>
    )
  }

  // Toolbar body templates
  const topLeftToolbarTemplate = () => {
    return (
      <div className='d-flex'>
        <Button
          type='button'
          icon='pi pi-filter-slash'
          label='Clear'
          outlined
          onClick={initLazyState}
          disabled={clearBtnDisabled}
        />
      </div>
    )
  }

  const topRightToolbarTemplate = () => {
    return (
      <div className='d-flex'>
        <Button
          label='New Manufacturer'
          icon='pi pi-plus'
          severity='success'
          onClick={() => AddEditRef.current.openNew()}
        />
      </div>
    )
  }

  return (
    <>
      <div className='card flex justify-content-center'>
        <Toast ref={toast} />
      </div>

      <MainContentLayout
        title='Manufacturers'
        dt={exportCSV}
        handleExport={true}
        loading={csvLoading}
      >
        <div className='card'>
          <Toolbar
            className='mb-2'
            left={topLeftToolbarTemplate}
            right={topRightToolbarTemplate}
          />

          <DataTable
            ref={dt}
            value={manufacturers}
            dataKey='id'
            paginator
            lazy
            showGridlines
            removableSort
            rowsPerPageOptions={[1, 5, 10, 25, 50]}
            totalRecords={totalRecords}
            // totalRecords='4'
            loading={tableLoading}
            globalFilterFields={['name']}
            selectionMode='single'
            // onRowSelect={onRowSelect}
            // selection={selectedMolecule}
            // onSelectionChange={(e) => setSelectedMolecule(e.value)}
            emptyMessage='No manufacturers found.'
            filters={lazyState?.filters}
            sortField={lazyState?.sortField}
            sortOrder={lazyState?.sortOrder}
            rows={lazyState?.rows}
            first={lazyState?.first}
            onPage={onPage}
            onSort={onSort}
            onFilter={onFilter}
          >
            <Column
              field='name'
              header='Name'
              sortable
              style={{ minWidth: '16rem' }}
              filterPlaceholder='Filter by Name'
              filter
              filterElement={manufacturerNameFilterElement}
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
            ></Column>
            <Column
              field='gpIsOrdable'
              header='Direct Orders'
              style={{ minWidth: '16rem' }}
              body={(rowData) => (rowData.gpIsOrdable ? 'Yes' : 'No')}
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
            ></Column>
            <Column
              field='email'
              header='Email'
              style={{ minWidth: '12rem' }}
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
            ></Column>
            <Column
              field='gpMinOrderPrice'
              header='Min Order Price'
              style={{ minWidth: '12rem' }}
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
            ></Column>
            <Column
              field='leadTime'
              header='Lead Time'
              style={{ minWidth: '12rem' }}
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
            ></Column>
            <Column
              field='cutOffTime'
              header='Cut Off Time'
              style={{ minWidth: '12rem' }}
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
            ></Column>
            <Column
              field='comingSoon'
              header='Status'
              style={{ minWidth: '12rem' }}
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
              body={statusTemplate}
            ></Column>
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ minWidth: '12rem' }}
            ></Column>
          </DataTable>

          <ModifyManufacture ref={refs} getData={getData} />
        </div>
      </MainContentLayout>
    </>
  )
}
